import React, { Component } from 'react';
import './App.css';
import './bootstrap.min.css';
import discord from './discord.svg';
import devto from './dev-dot-to.svg';
import github from './github.svg';
import mail from './mail.svg';

class App extends Component {
  render() {
    return (
      <div className="App vh-100">
        <div className="h-70">
          <div className="py-5 container" >
            <div class="row box-bio mx-auto">
              <div class="container">
                <div class="py-5">
                  <p class="text-center text-light h1">hug0</p>
                </div>
                <div class="line-separator"></div>
                <div class="p-5">
                  <a href="https://projects.hug0.xyz"><p class="h2 text-center text-light">Projets</p></a>
                </div>
                <div class="div-icon">
                  <div class="line-separator"></div>
                  <div class="px-5 pt-5 svg-list">
                    <div>
                      <img src={discord} class="svg-icon" />
                      <p class="text-center text-light">hug0#4846</p></div>
                    <a href="https://dev.to/hug0git"><img src={devto} class="svg-icon" /></a>
                    <a href="https://github.com/hug0git"><img src={github} class="svg-icon" /></a>
                    <a href="mailto:contact@hug0.xyz"><img src={mail} class="svg-icon" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
